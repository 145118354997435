.input-org {
    width: 5rem;
}

.wrap-text {
    white-space: normal;
    word-wrap: break-word;
}

.title-center {
    text-align: center;
    font-weight: bold;
    padding: 10px;
    display: flex;
    justify-content: center;
}

.subtable {
    width: 100%;
    border-collapse: collapse;
    border: none;
}

.subtable th, .subtable td {
    border: 1px solid #ddd;
    text-align: center;
    border-bottom: 1px solid #ddd; 
}

.subtable th {
    background-color: #f2f2f2;
    font-weight: bold;
    border-bottom: none;
}

.subtable td {
    border-bottom: 1px solid #ddd !important ;
}

