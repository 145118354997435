.input-machine {
    display: inline-block;
    min-width: 5rem;
}

.view:hover {
    font-size: 33px;
    color: #3C84AB;
}

.detail-button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.card-top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 15%;
    margin: 0 auto;
    margin-bottom: 10px;
    max-width: 1000px;
}