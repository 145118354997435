.tableContainer {
    max-width: 100%;
    max-height: 600px;
    overflow: auto;
}

.innerTableContainer {
    min-width: 500px;
}

.donwnloadDetectionContainer {
    max-width: 100%;
    min-width: 300px;
    max-height: 600px;
    margin-bottom: 50px;
}