.machine-list-loading-box {
    text-align: center;
    margin-top: 10;
}

.detection-loading-box {
    margin: 10px 25px;
    padding-right: 100px;
}

.capture-loading-box {
    margin: 10px 25px;
    padding-left: 100px;
}

@media screen and (max-width: 1200px) {
    .detection-loading-box, .capture-loading-box {
        margin: 30px auto;
        padding: 0;
    } 
    
    .detection-loader, .capture-loader {
        margin: 10px auto;
    }
}

.table-img {
    width: 350px !important;
    height: 250px !important;
    border-radius: 15px;
    object-fit: cover;
}