
body {
  /* text-align: center; */
  /* padding: 50px; */
  background-color: #ffffff;
  color: rgb(7, 7, 7);
  padding: 0;
  margin: 0;
  font-size: 120%;
}

input {
  font-size: 80%;
}
button {
  font-size: 80%;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
  font-size: 120%;
}
