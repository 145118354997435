.input-text {
    width: 90px;
}

.bg-light-blue {
    background-color: #ECF2FF;
}

.sync-lfc-data-box {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    max-width: 400px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
}