.machine-list-loading-box {
    text-align: center;
    margin-top: 10;
}

.detection-loading-box {
    margin: 10px 25px;
    padding-right: 100px;
}

.capture-loading-box {
    margin: 10px 25px;
    padding-left: 100px;
}

.capture-card:hover {
    box-shadow: 0px 0px 15px gray;
}

.card-img {
    width: 350px;
    height: 250px;
    object-fit: cover;
}

.table-img {
    width: 350px;
    height: 250px;
    border-radius: 15px;
    object-fit: cover;
}

.remark-container {
    max-width: 100px;
    overflow-x: scroll;
}

@media screen and (max-width: 1200px) {
    .detection-loading-box, .capture-loading-box {
        margin: 30px auto;
        padding: 0;
    } 
    
    .detection-loader, .capture-loader {
        margin: 10px auto;
    }
}

