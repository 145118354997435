.weight-log-swiper {
    max-width: 800px;
    object-fit: cover;
    border-radius: 20px;
}

.weight-log-swiper img {
    max-height: 400px;
    max-width: 800px;
    border-radius: 20px;
}

.weight-log-swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}