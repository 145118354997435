.active {
    background-color: #5C5C5C;
}

.nav-link:hover {
    background-color: #5C5C5C;
    cursor: pointer;
}

.icon{
    color: white;
}